<template>
  <div id="add-credit-view">
    <!-- BREADCRUMB -->
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'CreditBalance'">
      <template v-slot:right-end>

      </template>
    </breadcrumb-base>

    <div class="flex flex-row justify-end items-center">
      <div class="vx-row my-5 mx-3 flex gap-y-4" v-if="Object.keys(customer).length > 1">
        <div class="bg-white shadow rounded px-4 py-6 vx-col mx-2 sm:w-auto w-full">
          <h3><strong>{{ $t('CreditLimit') }}</strong></h3>

          <h4 class="pt-2">{{ customer.credit_limit }}</h4>
        </div>
        <div class="bg-white shadow rounded px-4 py-6 vx-col mx-2 sm:w-auto w-full">
          <h3><strong>{{ $t('BalanceTopUp') }}</strong></h3>

          <h4 class="pt-2">{{ customer.balance_top_up }}</h4>
        </div>
        <div class="bg-white shadow rounded px-4 py-6 vx-col mx-2 sm:w-auto w-full">
          <h3><strong>{{ $t('BalanceTrigger') }}</strong></h3>

          <h4 class="pt-2">{{ customer.balance_trigger }}</h4>
        </div>
        <div class="bg-white shadow rounded px-4 py-6 vx-col mx-2 sm:w-auto w-full">
          <h3><strong>{{ $t('Organisation') }}</strong></h3>

          <h4 class="pt-2">{{ customer.name }}</h4>
        </div>
        <div class="bg-white shadow rounded px-4 py-6 vx-col mx-2 sm:w-auto w-full">
          <h3><strong>{{ $t('CustomerName') }}</strong></h3>

          <h4 class="pt-2">{{ customer.first_name || customer.name || '' }}
            {{ customer.last_name || '' }}</h4>
        </div>
        <div class="bg-white shadow rounded px-4 py-6 vx-col  mx-2 sm:w-auto w-full">
          <h3><strong>{{ $t('CustomerID') }}</strong></h3>
          <h4 class="pt-2" style="display: flex;">
            <feather-icon icon="CircleIcon" class="pr-2"
                          :svgClasses="['fill-current h-6 w-6', textColor]"/>
            {{ customer.customer_id || '' }}
          </h4>
        </div>
        <div class="bg-white shadow rounded px-4 py-6 vx-col  mx-2 sm:w-auto w-full"
             v-if="Object.keys(customer).length > 1">
          <h3><strong>{{ $t('Manager') }}</strong></h3>
          <h4 class="pt-2">{{ customer.manager || 'N/A' }}</h4>
        </div>
        <div class=" bg-white shadow rounded px-4 py-6 vx-col  mx-2 sm:w-auto w-full"
             v-if="Object.keys(customer).length > 1">
          <h3><strong>{{ $t('Staff') }}</strong></h3>
          <h4 class="pt-2">{{ customer.reference || 'N/A' }}</h4>
        </div>
        <!--          <div class="bg-white shadow rounded p-4 vx-col mx-2  sm:w-auto w-full"-->
        <!--               style="width: 300px;"-->
        <!--               v-if="customer && customer.remarks">-->
        <!--            <h3><strong>{{ $t('Notes') }}</strong></h3>-->
        <!--            <vs-alert :active="customer && customer.remarks" :color="color" icon="new_releases">-->
        <!--              <span>{{ customer.remarks }}</span>-->
        <!--            </vs-alert>-->
        <!--          </div>-->
      </div>
    </div>

    <div class="flex flex-col">

      <div class="vx-row w-full my-2 mx-2">
        <vx-card>

          <div class="vx-row">
            <div class="vx-col w-full ">
              <div class="vx-row mx-3 pr-4 w-full flex" style="place-items: center"
                   v-if="Object.keys(customer).length > 1">

                <div class="w-full lg:w-2/8 cursor-pointer"
                     @click="showOverDueModal = true"
                     v-if="Object.keys(customer).length > 1">
                  <div class="bg-gray shadow rounded p-4 mr-3" style="font-size: 18px"
                       v-if="customer.over_due_amount > 0">
                    <h3>{{ $t('Overdue') }} {{ $t('Amount') }}
                      <strong>{{ customer.over_due_amount | germanNumberFormat }}</strong>
                      <br/>
                      {{ $t('UsedIn').toLowerCase() }} <strong>{{ customer.over_due_days }}
                        {{ $t('Days') }}</strong></h3>
                    <!--                    <h4>{{ customer.over_due_days }} {{ $t('Days') }} - {{ customer.over_due_amount | germanNumberFormat }}  </h4>-->
                  </div>
                </div>
                <table class="custom-table hidden md:table w-full lg:w-6/8 " v-if="customer"
                       style="">
                  <thead>
                  <tr>
                    <th>{{ $t('Credit') }}</th>
                    <th></th>
                    <th>{{ $t('TotalCash') }}</th>
                    <th></th>
                    <th>{{ $t('CreditNote') }}</th>
                    <th></th>
                    <th>{{ $t('TotalSales') }}</th>
                    <th></th>
                    <th>{{ $t('TotalBalance') }}</th>
                  </tr>
                  </thead>
                  <tr style="font-weight: bold; font-size: 20px;">
                    <td>{{ customer.total_credit | germanNumberFormat }}</td>
                    <td class="sign">+</td>
                    <td>{{ customer.total_cash | germanNumberFormat }}</td>
                    <td class="sign">+</td>
                    <td>{{ customer.total_credit_note | germanNumberFormat }}</td>
                    <td class="sign">-</td>
                    <td>{{ customer.total_sales | germanNumberFormat }}</td>
                    <td class="sign">=</td>
                    <td>
                      <strong>{{
                          customer.balance | germanNumberFormat
                        }}</strong>
                    </td>
                  </tr>
                </table>

                <!--                Mobile Table-->
                <table class="mobile-custom-table custom-table sm:hidden w-full lg:w-6/8 "
                       v-if="customer" style="">
                  <thead>
                  <tr>
                    <th>{{ $t('Kredit') }}</th>
                    <td style="font-weight: bold; font-size: 20px;">
                      {{ customer.total_credit | germanNumberFormat }}
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td class="sign">+</td>
                  </tr>
                  <tr>
                    <th>{{ $t('TotalCash') }}</th>
                    <td style="font-weight: bold; font-size: 20px;">
                      {{ customer.total_cash | germanNumberFormat }}
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td class="sign">+</td>
                  </tr>
                  <tr>
                    <th>{{ $t('CreditNote') }}</th>
                    <td style="font-weight: bold; font-size: 20px;">
                      {{ customer.total_credit_note | germanNumberFormat }}
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td class="sign">-</td>
                  </tr>
                  <tr>
                    <th>{{ $t('TotalSales') }}</th>
                    <td style="font-weight: bold; font-size: 20px;">
                      {{ customer.total_sales | germanNumberFormat }}
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <td class="sign">=</td>
                  </tr>
                  <tr>
                    <th>{{ $t('TotalBalance') }}</th>
                    <td style="font-weight: bold; font-size: 20px;">{{
                        customer.balance | germanNumberFormat
                      }}
                    </td>
                  </tr>
                  </thead>


                </table>
              </div>
              <div class="py-2"></div>

              <div class="vs-row w-full flex gap-y-4" style="place-items: end">
                <div class="vs-col w-full sm:pb-6 md:w-2/7 px-4 ">
                  <dynamic-customers-drop-down
                    class="w-full "
                    :only-active="true"
                    :placeholder="$t('SearchCustomer')"
                    :selected-item="customer"
                    @on-item-selected="onCustomerChanged($event)">
                  </dynamic-customers-drop-down>
                </div>

                <div class="vs-col w-full md:w-1/7 px-4">
                  <vs-input class="inputx sm:pb-6 w-full"
                            type="text"
                            @input="gNumberTransform()"
                            :label="$t('Amount') + '*'"
                            :placeholder="$t('Amount') + '*'"
                            v-model="amount"/>
                </div>


                <div class="vs-col w-full md:w-1/7 px-4">
                  <vs-select v-model="selectedPaymentMode"
                             autocomplete
                             class="select-large sm:pb-6  w-full"
                             :label="$t('PaymentMode') + '*'">
                    <vs-select-item :key="index" :value="item"
                                    :text="getCreditModeText(item) | title | uppercase"
                                    v-for="(item,index) in paymentData"/>
                  </vs-select>
                </div>

                <div class="vs-col w-full md:w-3/7 pl-4">
                  <vs-input class="inputx sm:pb-6 w-full"
                            :label="$t('Remark')"
                            :placeholder="$t('Remark')" v-model="remark"/>
                </div>
              </div>

              <div class="mt-4">


                <vs-button class="mt-4 mb-8 sm:mt-4 flex"
                           :disabled="addingCredit"
                           style="margin: auto" type="filled" @click="addCreditToUser">
                  {{ $t('Submit') }}
                </vs-button>
              </div>
              <div class="mt-4 px-2 flex items-center flex-row mx-2 w-full"
                   v-if="customer && customer.remarks">
                <h3 class="pr-2"><strong>{{ $t('Notes') }}</strong></h3>
                <vs-alert class="w-full remark-alert" :active="customer && customer.remarks" :color="color"
                          icon="new_releases">
                  <span>{{ customer.remarks }}</span>
                </vs-alert>
              </div>
            </div>
          </div>
        </vx-card>
      </div>


      <div class="vx-row">
        <div class="vx-col w-full">

          <!--    Error List State-->
          <transition name="fade">
            <div class="h-screen flex w-full bg-img" v-if="errorFetching">
              <div
                class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto text-center">
                <img src="@/assets/images/pages/svg_error.svg" :alt="$t('NoData')"
                     class="mx-auto mb-4 max-w-full">
                <h1 class="sm:mx-0 mx-4 mb-12 text-3xl">{{ $t('ErrorData') }}</h1>
              </div>
            </div>
          </transition>
          <vx-card v-if="walletHistory.length > 0" class="hidden md:block">
            <vs-table-modified stripe v-show="walletHistory.length > 0 && !errorFetching" ref="table"
                      class="custom-history-table"
                      :data="walletHistory">
              <template slot="header">
                <div class="flex flex-row items-end justify-end space-x-2 w-full mb-4">

                  <vs-button cl @click="enableViewAll()">{{ viewAll ? $t('ShowLess') : $t('ViewAll') }}</vs-button>

                </div>
              </template>


              <template slot="thead">
                <vs-th>{{ $t('Date') }}</vs-th>
                <vs-th>{{ $t('TransactionMode') }}</vs-th>
                <vs-th>{{ $t('OldCredit') }}</vs-th>
                <vs-th>{{ $t('NewCredit') }}</vs-th>
                <vs-th>{{ $t('Value') }}</vs-th>
                <vs-th>{{ $t('OldBalance') }}</vs-th>
                <vs-th>{{ $t('NewBalance') }}</vs-th>
                <vs-th>{{ $t('Remarks') }}</vs-th>
                <vs-th>{{ $t('User') }}</vs-th>
                <vs-th>{{ $t('CustomerId') }}</vs-th>
                <vs-th>{{ $t('Customer') }}</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                <vs-tr :data="tr"
                       style="font-weight: 600;"
                       :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class=" truncate"
                       style="max-width: 100px;white-space: normal !important;padding: 0 8px;">
                      {{ tr.updated | dateFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate">{{ tr.transaction_mode | title | uppercase }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate">{{ tr.to_standing_credit | germanNumberFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate">{{ tr.to_credit | germanNumberFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate" style="font-weight: bold;color:rgb(var(--vs-primary));">
                      {{ tr.value | germanNumberFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate">{{ tr.to_standing_balance | germanNumberFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate">{{ tr.to_balance | germanNumberFormat }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate"
                       style="max-width: 180px;white-space: normal !important;padding: 0 8px;">
                      {{ tr.remarks }}</p>
                  </vs-td>
                  <vs-td>
                    <p class=" truncate">{{ tr.staff }}</p>
                  </vs-td>
                  <vs-td>
                    <div>
                      <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>
                      <p class="font-medium truncate">{{ tr.organisation_name }}</p>
                    </div>
                  </vs-td>
                  <vs-td>
                    <div>
                      <p class="font-medium truncate">{{ tr.first_name }} {{ tr.last_name }}</p>
                      <p style="font-size: 11px; ">
                        {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                      </p>

                      <p style="font-size: 11px;">
                        {{ tr.postal }} {{ tr.city }}
                      </p>
                    </div>
                  </vs-td>
                </vs-tr>
                </tbody>
              </template>
              <template slot="footer-left">
                <div class="flex">
                  <vs-new-pagination :total="vTotalPages" v-model="currentPage" v-on:change="listPageChanged">
                  </vs-new-pagination>
                </div>
              </template>
            </vs-table-modified>
          </vx-card>
          <div class="table-mobile-wallet md:hidden my-4" v-if="customer.customer_id">
            <!--            <div class="input">-->
            <!--              <input-->
            <!--                class="w-full px-4 py-2 rounded-lg border-2 border-primary"-->
            <!--                v-model="searchWalletText"-->
            <!--                placeholder="Search">-->
            <!--            </div>-->
            <div class="" v-if="walletHistory.length > 0">

              <table v-for="(tr, index) in walletHistory">

                <tbody>
                <tr>
                  <th>{{ $t('Date') }}</th>
                  <th>{{ tr.updated | dateFormat }}</th>
                </tr>
                <tr>
                  <th>{{ $t('TransactionMode') }}</th>
                  <th>{{ tr.transaction_mode | title | uppercase }}</th>
                </tr>
                <tr>
                  <th>{{ $t('OldCredit') }}</th>
                  <th>{{ tr.to_standing_credit | germanNumberFormat }}</th>
                </tr>
                <tr>
                  <th>{{ $t('NewCredit') }}</th>
                  <th>{{ tr.to_credit | germanNumberFormat }}</th>
                </tr>
                <tr>
                  <th class="text-primary">{{ $t('Value') }}</th>
                  <th class="text-primary">{{ tr.value | germanNumberFormat }}</th>
                </tr>
                <tr>
                  <th>{{ $t('OldBalance') }}</th>
                  <th>{{ tr.to_standing_balance | germanNumberFormat }}</th>
                </tr>
                <tr>
                  <th>{{ $t('NewBalance') }}</th>
                  <th>{{ tr.to_balance | germanNumberFormat }}</th>
                </tr>
                <tr>
                  <th>{{ $t('Remarks') }}</th>
                  <th>{{ tr.remarks }}</th>
                </tr>
                <tr>
                  <th>{{ $t('User') }}</th>
                  <th>{{ tr.staff }}</th>
                </tr>
                <tr>
                  <th>{{ $t('CustomerId') }}
                  </th>
                  <th>
                    <div>
                      <p class="user-name font-medium truncate">{{ tr.customer_id }}</p>
                      <p class="font-medium truncate">{{ tr.organisation_name }}</p>
                    </div>
                  </th>
                </tr>
                <tr>
                  <th>{{ $t('Customer') }}</th>
                  <th>
                    <div>
                      <p class="font-medium truncate">{{ tr.first_name }} {{ tr.last_name }}</p>
                      <p style="font-size: 11px; ">
                        {{ tr.address }}<span v-if="tr.house_no"> {{ tr.house_no }}</span>
                      </p>

                      <p style="font-size: 11px;">
                        {{ tr.postal }} {{ tr.city }}
                      </p>
                    </div>
                  </th>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>


    <vx-modal v-if="showOverDueModal" @close="showOverDueModal = false"
              component-class="modal-container-fit">
      <template slot="header"></template>
      <template slot="body">
        <div class="p-2 flex flex-centered">
          <customer-projects-component
            @close="showOverDueModal = false"
            :projections="customer.projection_records"/>
        </div>
      </template>
    </vx-modal>

  </div>
</template>

<script>
import DynamicCustomersDropDown from '@/views/pages/superadmin/customer/DynamicCustomersDropDown'
import VxModal from "@/layouts/components/custom/VxModal";
import CustomerProjectsComponent
  from "@/views/pages/superadmin/customer/components/CustomerProjectsComponent";

export default {
  name: 'AddCredits',
  data() {
    return {
      customer: {},
      currentPage: 1,
      showOverDueModal: false,
      isMounted: false,
      errorFetching: false,
      amount: '',
      selectedPaymentMode: null,
      remark: '',
      addingCredit: false,
      viewAll: false,
      // paymentData: [
      //   'cash',
      //   'credit',
      //   'btc',
      //   'tmci',
      //   'bank_transfer',
      //   'credit_note',
      // ],
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'Customers', i18n: 'Customers', url: '/customers/view'},
        {title: 'Add Credit', i18n: 'AddCredit', active: true},
      ],
    }
  },
  components: {
    CustomerProjectsComponent,
    VxModal,
    DynamicCustomersDropDown,
  },
  computed: {
    paymentData() {
      let list = this.activeUserInfo.credit_transaction_type_modes;
      return list
        .sort(function (a, b) {
          return a < b ? -1 : 1;
        })
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    textColor() {
      if (this.customer.flag == 0) {
        return 'text-danger'
      }
      if (this.customer.flag == 1) {
        return 'text-success'
      }
      if (this.customer.flag == 2) {
        return 'text-warning'
      }
      return this.customer.flag ? 'text-primary' : 'text-grey'
    },
    color() {
      if (this.customer.flag == 0) {
        return 'danger'
      }
      if (this.customer.flag == 1) {
        return 'success'
      }
      if (this.customer.flag == 2) {
        return 'warning'
      }
      return 'primary'
    },
    vPage() {
      return this.$store.state.customer.vPage
    },
    vTotalCount() {
      return this.$store.state.customer.vTotalCount
    },
    vTotalPages() {
      return this.$store.state.customer.vTotalPages
    },
    walletHistory() {
      return this.$store.state.customer.walletHistory
    },
  },
  props: {
    customerId: {
      type: String | Number,
    },
  },
  methods: {
    enableViewAll() {
      this.viewAll = !this.viewAll;
      this.fetchWalletHistory();
    },
    getCreditModeText(mode) {
      if (mode == 'credit_note') return 'Gutschrift'
      if (mode == 'credit') return 'Kredit'
      return mode
    },
    gNumberTransform() {
      this.amount = this.amount.replace(".", ",")
    },
    onCustomerChanged(cust) {
      this.customer = cust
      this.fetchCustomer()
      this.resetData();

      this.fetchWalletHistory()
    },
    getTransactionType(value) {
      if (value === 0) return 'Credit'
      if (value === 1) return 'Cash'
      return ''
    },
    resetData() {
      this.selectedPaymentMode = null
      this.amount = ''
      this.remark = ''
      this.fetchWalletHistory()
      this.fetchCustomer()
    },
    addCreditToUser() {
      if (this.addingCredit === true) return
      let value = this.amount.replace(",", ".")
      if (!value || value === '' || isNaN(value)) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: this.$t('AmountError'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }
      if (this.customer.credit_limit > 0) {
        let type = this.selectedPaymentMode === 'btc' || this.selectedPaymentMode === 'credit' ? 0 : 1
        if (type === 0 && value > this.customer.credit_limit && value != 0) {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: this.$t('AmountCreditLimitError'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
          return
        }
      }
      if (!this.customer.customer_id) {
        this.$vs.notify({
          position: 'top-center',
          title: 'Error',
          text: 'Please select the customer',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        return
      }

      this.addingCredit = true;
      const data = {
        customer_id: this.customer.customer_id,
        transaction_type: this.selectedPaymentMode === 'btc' || this.selectedPaymentMode === 'credit' ? 0 : 1,
        transaction_mode: this.selectedPaymentMode,
        value: value,
        remarks: this.remark,
      }
      this.$vs.loading()
      this.$store.dispatch('customer/processCustomerWalletAmount', data)
        .then((data) => {

          this.addingCredit = false;
          this.$vs.loading.close()
          //
          this.resetData()
          // this.$router.push({name: 'customer-details', params: { customerId: this.customer.customer_id }})
        })
        .catch((error) => {
          this.addingCredit = false;
          this.$vs.loading.close()
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchCustomer() {
      this.$store.dispatch('customer/fetchCustomerDetails', this.customer.customer_id)
        .then((data) => {
          this.customer = data
        })
        .catch((error) => {
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },

    listPageChanged(item) {
      this.fetchWalletHistory(this.currentPage - 1)
    },
    fetchWalletHistory(page = '0') {
      let payload = {}
      if(Object.keys(this.customer).length > 0) {
        payload["customer_id"] = this.customer.customer_id
        payload["user_id"] = this.customer.customer_id
      }

      if(this.viewAll) {
        payload['view_all'] = this.viewAll ? 1 : 0;
      }
      if (page == 0) this.currentPage = 1
      this.$vs.loading()
      this.$store.dispatch('customer/fetchCustomerWalletHistory', {page, payload})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  created() {
    this.customer = {
      'customer_id': this.customerId,
    }
    this.$store.commit('customer/RESET')

    if (this.customerId) {
      if (this.customerId) this.fetchCustomer()
      this.fetchWalletHistory()
    }
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
.amount-forecasted {
  color: #7cbb00;
  font-weight: bold;
}

.vx-card__title > h4 {
  font-size: 21.14px !important;
}

#add-credit-view {

  .custom-history-table {
    td {
      color: #515151 !important;
      font-weight: 500;
    }
  }

  .vs-input--label {
    font-weight: bold;
  }

  .dropdown {
    max-width: 100% !important;
  }

  .vs-table {
    .vs-table--thead {
      background-color: rgba(var(--vs-primary), 1);
      color: white;

      tr {
        background-color: rgba(var(--vs-primary), 1);
      }
    }
  }

  .custom-table {
    border: 1px solid rgba(var(--vs-primary), 1);
    border-collapse: collapse;

    thead {
      background-color: rgba(var(--vs-primary), 1);
      //tr {
      //  border: 1px solid rgba(var(--vs-primary), 1) !important;
      //}
    }

    th {
      color: white;
      text-align: center;
    }

    td {
      font-size: 16px;
      text-align: center;
    }

    .sign {
      font-size: 24px;
    }
  }

  .mobile-custom-table {
    thead {
      background-color: white;

      th {
        background-color: rgba(var(--vs-primary), 1);
      }
    }
  }

  .table-mobile-wallet {
    table {
      width: 100%;
      border-collapse: collapse;
      margin: 2rem 0;
    }

    /* Zebra striping */
    tr:nth-of-type(odd) {
      background: #eee;
    }

    tr {
      margin: 10px;

      th:first-child {
        width: 140px;
      }
    }

    //th {
    //  background: #333;
    //  color: white;
    //  font-weight: bold;
    //}
    td, th {
      padding: 6px;
      border: 1px solid #ccc;
      text-align: left;
    }
  }
}

</style>
